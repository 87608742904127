import { Notification, NotificationType } from 'api/notifications'
import cx from 'classnames'
import { Tag } from 'components/notifications/Tag'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import Link from 'next/link'
import { isNearlyGone } from 'utils/artwork'
import { formatShortRelativeTime } from 'utils/dates'
import { ARTWORK_IMG_PLACEHOLDER } from 'utils/image'
import {
  getActionsBehavior,
  getNotificationContent,
  isFollowedArtistNotification,
  isSavedArtworkNotification,
} from 'utils/notifications'

const sizes = {
  sm: {
    title: 'text-xs',
    description: 'text-2xs',
    image: 'max-h-[3.75rem] min-h-[3.75rem] min-w-[3.75rem] max-w-[3.75rem]',
  },
  default: {
    title: 'text-base',
    description: 'text-xs',
    image: 'max-h-[3.75rem] min-h-[3.75rem] min-w-[3.75rem] max-w-[3.75rem]',
  },
} as const

const themes = {
  default: {
    imageContainer: 'group-hover/notification:bg-warm-100',
    dateTag: 'group-hover/notification:bg-cool-50',
    textContainer: 'group-hover/notification:bg-warm-100',
  },
  gray: {
    imageContainer: '!bg-warm-100',
    dateTag: '!bg-cool-50',
    textContainer: '!bg-warm-100',
  },
}

export type Props = {
  classNames?: {
    root?: string
    imageContainer?: string
    textContainer?: string
  }
  size?: keyof typeof sizes
  theme?: keyof typeof themes
} & Notification

export const NotificationComponent = (props: Props) => {
  const { t } = useTranslation('notifications')

  const {
    artwork,
    viewed,
    classNames,
    size = 'default',
    theme = 'default',
    type,
  } = props
  const { title } = getNotificationContent(props, t)

  const sizeStyles = sizes[size]
  const themeStyles = themes[theme]

  const artworkImage = artwork?.images?.[0]?.url

  return (
    <div
      className={cx(
        'flex overflow-hidden group/notification',
        classNames?.root,
        !viewed && 'bg-purple-50 border-t border-purple-600'
      )}
    >
      <div
        className={cx(
          'flex items-center justify-center min-w-[5rem] px-2.5 py-4 transition-colors',
          viewed && 'bg-warm-50',
          classNames?.imageContainer,
          themeStyles.imageContainer
        )}
      >
        <Image
          src={artworkImage || ARTWORK_IMG_PLACEHOLDER}
          alt={artwork?.name || 'placeholder'}
          width={60}
          height={60}
          className={cx('object-contain', sizeStyles.image)}
        />
      </div>

      <div
        className={cx(
          'w-full px-5 py-4 flex items-center space-x-3 justify-between transition-colors',
          classNames?.textContainer,
          themeStyles.textContainer
        )}
      >
        <div>
          <div className='flex items-center mb-1 space-x-1'>
            <Tag
              className={cx(
                viewed ? 'bg-warm-100' : 'bg-cool-50',
                'text-cool-800 transition-colors',
                themeStyles.dateTag
              )}
            >
              {formatShortRelativeTime(new Date(props.createdAt))}
            </Tag>

            {isFollowedArtistNotification(type) && (
              <Tag className='bg-purple-50 text-purple-600'>
                {t('followed_artist')}
              </Tag>
            )}

            {isSavedArtworkNotification(type) && (
              <Tag className='bg-purple-50 text-purple-600'>
                {t('saved_artwork')}
              </Tag>
            )}

            {isNearlyGone(artwork?.spreadPercentage) && (
              <Tag className='bg-warning-light text-warning-dark'>
                {t('nearly_gone')}
              </Tag>
            )}
          </div>

          <div className='flex w-full items-center justify-between'>
            <h6
              className={cx(
                'font-body font-medium tracking-normal',
                sizeStyles.title
              )}
            >
              {title}
            </h6>
          </div>

          <p
            className={cx(
              'line-clamp-2 w-full whitespace-pre-line text-xs text-cool-600 space-x-1 text-start',
              sizeStyles.description
            )}
          >
            <span>{artwork?.artists?.[0]?.name}</span>
            <span className='text-cool-400'>|</span>
            <span>{artwork?.name}</span>
          </p>

          <NotificationAction {...props} />
        </div>

        {!viewed && (
          <div className='w-2 h-2 bg-primary-default rounded-full min-h-2 min-w-2' />
        )}
      </div>
    </div>
  )
}

const NotificationAction = (props: Props) => {
  const { t } = useTranslation('notifications')

  const { type } = props

  const { action, secondaryAction } = getNotificationContent(props, t)
  const { primary: onPrimaryAction, secondary: onSecondaryAction } =
    getActionsBehavior(props)

  if (!action && !secondaryAction) return null

  const isDanger = type === NotificationType.SALE_CONFIRMATION_PASS_AUTH

  return (
    <div className='mt-1 space-x-5 flex'>
      {action && onPrimaryAction && (
        <Link
          href={onPrimaryAction}
          className={cx(
            'text-xs underline',
            isDanger ? 'text-danger-dark' : 'text-secondary-default'
          )}
        >
          {action}
        </Link>
      )}

      {secondaryAction && onSecondaryAction && (
        <Link
          href={onSecondaryAction}
          className='text-xs text-secondary-default underline'
        >
          {secondaryAction}
        </Link>
      )}
    </div>
  )
}
